import { WaveHeaders } from "../utils/wavfileparser/wavfileparser";
import { Artist } from "./ProductTypes";
import { Contributor, PublisherOnTrack } from "./TrackTypes";
import { X5DspConfig } from "./X5Types";

export interface FileMetadata {
  upc: number;
  isrc: string;
  artists: string[];
  albumName: string;
  releaseDate: string;
  identifier: string;
  genre: string;
  fugaMainSubGenre: string;
  coverArtFileName: string;
  audioFileName: string;
  publishers: PublisherOnTrack[];
  contributors: Contributor[];
  composers: string[];
  languageOfMetadata: string;
  audioLanguage: string;
  featuredArtists: string[];
  lyricists: string[];
  providers: SheetProvider[];
  royaltyRate: string;
  qsGenre: string;
  qsCategory: string;
  seqNr: number;
  title: string;
  albumVersion?: string;
  trackVersion?: string;
  fugaProductArtists: string[];
  duration?: number;
  audio?: AudioMetadata;
  coverArt?: CoverArt;
  isCover: boolean;
  spotifyEndDate: string;
  label: string;
}

export interface LintedFileMetadata {
  upc: LintedMetadataValue<number>;
  isrc: LintedMetadataValue<string>;
  artists: LintedMetadataValue<Artist[]>;
  albumName: LintedMetadataValue<string>;
  releaseDate: LintedMetadataValue<string>;
  identifier: LintedMetadataValue<string>;
  genre: LintedMetadataValue<string>;
  fugaMainSubGenre: LintedMetadataValue<string>;
  coverArtFileName: LintedMetadataValue<string>;
  audioFileName: LintedMetadataValue<string>;
  publishers: LintedMetadataValue<PublisherOnTrack[]>;
  contributors: LintedMetadataValue<Contributor[]>;
  composers: LintedMetadataValue<string[]>;
  languageOfMetadata: LintedMetadataValue<string>;
  audioLanguage: LintedMetadataValue<string>;
  featuredArtists: LintedMetadataValue<Artist[]>;
  lyricists: LintedMetadataValue<string[]>;
  providers: LintedMetadataValue<SheetProvider[]>;
  royaltyRate?: LintedMetadataValue<string>;
  qsGenre: LintedMetadataValue<string>;
  qsCategory: LintedMetadataValue<string>;
  seqNr: LintedMetadataValue<number>;
  title: LintedMetadataValue<string>;
  albumVersion: LintedMetadataValue<string>;
  trackVersion: LintedMetadataValue<string>;
  fugaProductArtists: LintedMetadataValue<string[]>;
  audio: LintedMetadataValue<AudioMetadata | undefined>;
  coverArt: LintedMetadataValue<CoverArt | undefined>;
  isCover: LintedMetadataValue<boolean>;
  spotifyEndDate: LintedMetadataValue<string | undefined>;
  label: LintedMetadataValue<string>;
}

export interface CoverArt {
  file: File;
  dimension: number;
}

export interface AudioMetadata {
  file: File;
  duration: number;
  headers: WaveHeaders;
}

export interface SheetProvider {
  name: string;
  royaltyRate: number;
}

export interface LintedMetadataValue<T> {
  value: T;
  error: string | undefined;
}

export type SemiLintedMetadata<T> = {
  [K in keyof T]: LintedMetadataValue<T[K]> | T[K];
};

export interface BulkParseError {
  key: string;
  value: any;
  error: string;
}

export interface LintedMetadataPerProduct {
  [upc: string]: LintedFileMetadata[];
}

/**
 * Represents the intention to deliver to a DSP.
 */
export type TargetDSPs = {
  [K in DSP]: boolean;
} & {
  [key: string]: boolean;
};

export interface DeliveryJob {
  id: number;
  upc: number;
  dspId: number;
  dsp: DSP;
  deliveryStatus: DELIVERY_JOB_STATUS;
  deliveryError: string;
  deliveryAction: DeliveryAction;
  createdAt: Date;
  createdBy: string;
  deliveryType: DeliveryType;
  x5DspConfig: X5DspConfig;
  isDelivered: boolean;
  isDelivering: boolean;
  batchDeliveryId?: number;
}

export interface DeliveryJobInput {
  upc: number;
  dsps: DSP[];
  deliveryType: DeliveryType;
  deliveryAction: DeliveryAction;
  x5DspConfig: X5DspConfig;
  batchDeliveryId?: number;
}

export enum DSP {
  AMAZON = "AMAZON",
  X5 = "X5",
  SPOTIFY = "SPOTIFY",
  YOUTUBE_CONTENT_ID = "YOUTUBE_CONTENT_ID",
  YOUTUBE = "YOUTUBE",
  FUGA = "FUGA",
  BOOMPLAY = "BOOMPLAY",
}

export enum DeliveryType {
  STEREO = "STEREO",
  ATMOS = "ATMOS",
  MEAD = "MEAD",
}

export enum DeliveryAction {
  DELIVER = "DELIVER",
  UPDATE = "UPDATE",
  TAKEDOWN = "TAKEDOWN",
}

export enum DELIVERY_JOB_STATUS {
  JOB_CREATED = "JOB_CREATED",
  CHECKED_PRODUCT_FILES = "CHECKED_PRODUCT_FILES",
  VALIDATED_PRODUCT = "VALIDATED_PRODUCT",
  CREATED_DDEX_FILES = "CREATED_DDEX_FILES",
  DDEX_DELIVERY_DONE = "DDEX_DELIVERY_DONE",
  SFTP_TRANSFER_TASK_DISPATCHED = "SFTP_TRANSFER_TASK_DISPATCHED",
  SFTP_TRANSFER_STARTED = "SFTP_TRANSFER_STARTED",
  SFTP_CREATING_FOLDER_STRUCTURE = "SFTP_CREATING_FOLDER_STRUCTURE",
  SFTP_DELIVERING_TRACKS = "SFTP_DELIVERING_TRACKS",
  SFTP_DELIVERING_COVER_ART = "SFTP_DELIVERING_COVER_ART",
  SFTP_DELIVERING_XML = "SFTP_DELIVERING_XML",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  DSP_DELIVERY_CONFIRMED = "DSP_DELIVERY_CONFIRMED",
}
