import { useEffect, useState } from "react";
import "firebase/app";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  User,
  signInWithCredential,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { CLIENT_ID, firebaseAuth } from "./firebase";
import { Role } from "../AuthProvider";

const logStyle = [
  "background: #000",
  "color: #fff",
  "padding: 10px 20px",
  "line-height: 35px",
].join(";");

export const useFirebase = () => {
  const [currentUser, setcurrentUser] = useState<User | null>();
  const [gapiClient, setgapiClient] = useState<typeof gapi.client>();
  const [isAuthenticating, setisAuthenticating] = useState(true);
  const [userRoles, setUserRoles] = useState<Role[]>();

  const signIn = () => gapi.auth2.getAuthInstance().signIn();

  const signOut = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      console.log("Successfully signed out of API");
    });
    firebaseSignOut(firebaseAuth);
    setcurrentUser(null);
  };

  useEffect(() => {
    console.log("%c Starting auth flow", logStyle);
    initGAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBearerToken = () =>
    new Promise<gapi.auth2.AuthResponse>((res, rej) => {
      return gapi.auth2.getAuthInstance().then((auth2) => {
        const currentUser = auth2.currentUser.get();
        const authResponse = currentUser.getAuthResponse();

        if (authResponse && authResponse.expires_at <= Date.now()) {
          // Token has expired or will expire soon
          currentUser
            .reloadAuthResponse()
            .then((newAuthResponse) => {
              res(newAuthResponse);
            })
            .catch((error) => {
              rej(error);
            });
        } else {
          res(authResponse);
        }
      });
    });

  const signIntoFirebaseWithCredential = (
    id_token: string,
    access_token: string
  ) => {
    const credential = GoogleAuthProvider.credential(id_token, access_token);
    signInWithCredential(firebaseAuth, credential);
  };

  const initGAPI = () => {
    console.log("%c Initializing Google API", logStyle);

    const script = document.createElement("script");

    script.src = "https://apis.google.com/js/api.js";
    script.defer = true;

    script.onload = () => {
      gapi.load("client:auth2", () => {
        gapi.client
          .init({
            clientId: CLIENT_ID,
            scope:
              "profile openid https://www.googleapis.com/auth/devstorage.read_only https://www.googleapis.com/auth/drive",
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
            ],
          })
          .then(() => setgapiClient(gapi.client));

        const auth2 = gapi.auth2.getAuthInstance();

        onAuthStateChanged(firebaseAuth, (user) => {
          user?.getIdTokenResult().then((result) => {
            setUserRoles(result.claims["roles"] as Role[]);
            setcurrentUser(user);
            setisAuthenticating(false);
          });
          if (user === null) {
            setisAuthenticating(false);
          }
        });
        // Listen for sign-in state changes.
        auth2.isSignedIn.listen((signedIn) => {
          if (signedIn) {
            const currentUser = auth2.currentUser.get();
            const authResponse = currentUser.getAuthResponse(true);

            signIntoFirebaseWithCredential(
              authResponse.id_token,
              authResponse.access_token
            );
          }
        });
      });
    };
    document.body.appendChild(script);
  };

  return {
    signIn,
    signOut,
    currentUser,
    userRoles,
    gapiClient,
    getBearerToken,
    isAuthenticating,
  };
};
