import {
  getDownloadURL,
  ref,
  list,
  StorageReference,
  FirebaseStorage,
  deleteObject,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth } from "../components/auth/AuthProvider";

import { updateBlobStatus } from "../redux/blob/actions";
import { blobToFile, getStorageRefFromURI } from "./Helpers";
import { trackStorage } from "../components/auth/firebase/firebase";

export const useFileDownloader = (props?: { useGlobalState: boolean }) => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [loading, setloading] = useState<{ [fileName: string]: boolean }>({});
  const [error, seterror] = useState<string | undefined>();
  const [file, setfile] = useState<File>();
  const [existingFiles, setexistingFiles] = useState<{
    [url: string]: boolean;
  }>({});

  useEffect(() => {
    return () => {
      setexistingFiles({});
      seterror(undefined);
    };
  }, []);

  const downloadFromRef = async (
    ref: StorageReference,
    toDisk?: boolean
  ): Promise<File> =>
    new Promise(async (res, rej) => {
      getDownloadURL(ref).then(async (url) => {
        let xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.open("GET", url);
        xhr.onerror = (err) => {
          rej(err);
          //throw Error("Error downloading file");
        };
        xhr.onload = function (e) {
          if (this.status === 200) {
            const file = blobToFile(new Blob([this.response]), ref.name);
            setfile(file);
            res(file);

            if (toDisk?.valueOf()) {
              const href = window.URL.createObjectURL(file);
              const link = document.createElement("a");
              link.download = file.name;
              link.href = href;
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(href);
            }
          }

          if (this.status === 404) {
            throw Error(`Error 404: ${ref.name} does not exist.`);
          }
        };
        xhr.setRequestHeader(
          "Authorization",
          (await auth.getBearerToken()).access_token
        );

        xhr.onprogress = (ev) => {
          const p = Math.round((ev.loaded / ev.total) * 100);

          if (props?.useGlobalState === true) {
            dispatch(updateBlobStatus({ blobName: url, progress: p }));
          }
        };

        xhr.send();
      });
    });

  const downloadFile = (
    url: string,
    fileName: string,
    toDisk?: boolean
  ): Promise<File> =>
    new Promise(async (res, rej) => {
      setloading({ ...loading, [fileName]: true });
      const ref = getStorageRefFromURI(url);

      let downloadURL = "";
      try {
        downloadURL = await getDownloadURL(ref);
      } catch (error) {
        rej(error);
      }

      let xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", downloadURL);
      xhr.onerror = (err) => {
        setloading({ ...loading, [fileName]: false });
        seterror("Error downloading file");
        throw Error("Error downloading file");
      };
      xhr.onload = function (e) {
        setloading({ ...loading, [fileName]: false });

        if (this.status === 200) {
          const file = blobToFile(new Blob([this.response]), fileName);
          setfile(file);
          res(file);
          if (toDisk?.valueOf()) {
            const href = window.URL.createObjectURL(file);
            const link = document.createElement("a");
            link.download = file.name;
            link.href = href;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(href);
          }
        }

        if (this.status === 404) {
          rej(`Error 404: ${fileName} does not exist.`);
        }
      };
      xhr.setRequestHeader(
        "Authorization",
        (await auth.getBearerToken()).access_token
      );

      xhr.onprogress = (ev) => {
        const p = Math.round((ev.loaded / ev.total) * 100);

        if (props?.useGlobalState === true) {
          dispatch(updateBlobStatus({ blobName: url, progress: p }));
        }
      };

      xhr.send();
    });

  const checkFileExist = async (bucketURI: string) => {
    try {
      const url = await getDownloadURL(getStorageRefFromURI(bucketURI));
      if (url) {
        setexistingFiles((existingFiles) => ({
          ...existingFiles,
          [bucketURI]: true,
        }));
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getAuthorizedFLACURL = async (isrc: string) =>
    getDownloadURL(ref(trackStorage, `flac/${isrc}.flac`)).catch((err) => {
      //ignore
      return undefined;
    });

  const getAuthorizedWAVURL = async (isrc: string) =>
    getDownloadURL(ref(trackStorage, `/${isrc}.wav`)).catch((err) => {
      //ignore
      return undefined;
    });

  const getAuthorizedAudioUrl = async (isrc: string) => {
    const flacURL = await getAuthorizedFLACURL(isrc);
    if (flacURL) {
      return flacURL;
    } else {
      return await getAuthorizedWAVURL(isrc);
    }
  };

  const listFiles = async (storage: FirebaseStorage, path: string) =>
    list(ref(storage, path));

  const deleteFile = async (storage: FirebaseStorage, path: string) =>
    deleteObject(ref(storage, path));

  return {
    downloadFile,
    checkFileExist,
    getAuthorizedFLACURL,
    getAuthorizedWAVURL,
    getAuthorizedAudioUrl,
    listFiles,
    downloadFromRef,
    deleteFile,
    existingFiles,
    file,
    loading,
    error,
  };
};
